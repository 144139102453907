import React from "react";
import theme from "theme";
import { Theme, Text, Box, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Home Harmony Designs
			</title>
			<meta name={"description"} content={"Transforming Spaces, Creating Comfort"} />
			<meta property={"og:title"} content={"Home Harmony Designs"} />
			<meta property={"og:description"} content={"Transforming Spaces, Creating Comfort"} />
			<meta property={"og:image"} content={"https://tixelopride.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://tixelopride.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://tixelopride.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://tixelopride.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://tixelopride.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://tixelopride.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://tixelopride.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" color="--dark" quarkly-title="Contacts-8">
			<Override slot="SectionContent" flex-direction="row" align-items="flex-start" lg-flex-direction="column" />
			<Box
				min-width="100px"
				min-height="70vh"
				width="40%"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				justify-content="flex-start"
				lg-margin="0px 0 30px 0px"
				align-items="flex-start"
				sm-margin="0px 0px 0 0px"
				lg-padding="0px 180px 0px 0px"
				sm-padding="0px 0 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 20px 0px" font="normal 600 38px/1.2 --fontFamily-sansHelvetica" color="--dark">
				Get in Touch
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
					color="--darkD2"
					lg-margin="0px 0px 20px 0px"
					md-width="100%"
				>
					Ready to transform your space? Contact us to begin your journey to a more beautiful, functional home.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				margin="0px 0 0 10%"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-gap="36px 34px"
				md-grid-template-columns="1fr"
				flex-direction="column"
				width="50%"
				lg-margin="0px 0 0 0"
				lg-width="100%"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-darkD2"
					border-radius="4px"
					align-items="center"
					margin="0px 0px 30px 0px"
					lg-margin="0px 0px 10px 0px"
					sm-margin="0px 0px 0 0px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							color="--dark"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							246 Fosters Rd, Oakden SA 
						</Link>
						<Text
							margin="0px 0px 0 0px"
							font="normal 300 16px/1.2 --fontFamily-sans"
							text-align="center"
							color="--darkD2"
							md-margin="0px 0px 0px 0px"
						>
							5086, Australia
						</Text>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-darkD2"
					border-radius="4px"
					align-items="center"
					margin="0px 0px 30px 0px"
					lg-margin="0px 0px 10px 0px"
					sm-margin="0px 0px 0 0px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="tel:+61 8 8261 7045"
							color="--dark"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							+61 8 8261 7045
						</Link>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-darkD2"
					border-radius="4px"
					align-items="center"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="mailto:admin@tixelopride.com"
							color="--dark"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							admin@tixelopride.com
						</Link>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});